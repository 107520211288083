import { buttonClasses, Button as MuiButton } from '@mui/material';
import type { ButtonProps as MuiButtonProps } from '@mui/material';
import { colors } from 'styles/theme/colors';
import styled from '@emotion/styled';
type LinkButton = MuiButtonProps & {
  variant?: 'link' | 'linkBlack' | 'linkWhite';
  size?: 'lg' | 'md' | 'sm' | 'xl';
};
type OthersButton = MuiButtonProps & {
  size?: 'lg' | 'md' | 'sm';
};
type ButtonProps = LinkButton | OthersButton;

export const Button = ({ children, ...rest }: ButtonProps) => {
  return <StyledButton {...rest}>{children}</StyledButton>;
};
const StyledButton = styled(MuiButton)(({ theme }) => {
  return {
    height: 'fit-content',
    [`&.${buttonClasses.startIcon}`]: {
      marginRight: 'unset',
      marginLeft: 'unset',
    },
    [`&.${buttonClasses.endIcon}`]: {
      marginRight: 'unset',
      marginLeft: 'unset',
    },
  };
});
// Define the styles here and import them in styles/theme
// and use as basic styles for the themed button

export const primary = {
  borderRadius: '360px',
  color: colors.white,
  backgroundColor: colors.brand[400],
  borderColor: colors.brand[400],
  '&:hover': {
    backgroundColor: colors.brand[500],
    borderColor: colors.brand[500],
  },
  '&:focus': {
    boxShadow: '0px 0px 0px 4px rgba(22, 105, 99, 0.14)',
  },
  '&:disabled': {
    backgroundColor: colors.grey[100],
    color: colors.grey[600],
  },
};

export const secondary = {
  borderRadius: '360px',
  color: colors.brand[500],
  backgroundColor: colors.white,
  border: `1px solid ${colors.brand[300]}`,
  '&:focus': {
    boxShadow: '0px 0px 0px 4px rgba(18, 84, 79, 0.08)',
  },
  '&:disabled': {
    color: colors.grey[200],
    backgroundColor: colors.grey[0],
    borderColor: colors.grey[200],
  },
};

export const tertiary = {
  color: colors.grey[900],
  '&:hover': {
    backgroundColor: colors.brand[100],
    color: colors.brand[500],
  },
  '&:focus': {
    backgroundColor: colors.brand[100],
    color: colors.brand[500],
  },
  '&:disabled': {
    color: colors.grey[200],
  },
};
export const tertiaryOutline = {
  color: colors.grey[700],
  border: `0.5px solid ${colors.grey[200]}`,
  backgroundColor: colors.grey[0],
  borderRadius: '8px',
  '&:hover': {
    backgroundColor: colors.grey[50],
  },
  '&:focus': {
    backgroundColor: colors.grey[0],
    boxShadow: '0px 0px 0px 4px rgba(69, 135, 130, 0.08)',
  },
  '&:disabled': {
    color: colors.grey[200],
  },
};

export const quaternary = {
  borderRadius: '8px',
  color: colors.white,
  backgroundColor: colors.marineBlue[500],
  '&:hover': {
    backgroundColor: colors.marineBlue[300],
  },
  '&:focus': {
    backgroundColor: colors.marineBlue[500],
    boxShadow: '0px 0px 0px 4px rgba(81, 104, 255, 0.08)',
  },
  '&:disabled': {
    color: colors.grey[200],
    backgroundColor: colors.grey[100],
  },
};

export const linkButton = {
  color: colors.marineBlue[500],
  minWidth: 'max-content',
  gap: '4px',
  padding: 0,
  '&:hover': {
    color: colors.marineBlue[600],
  },
  '&:focus': {
    color: colors.marineBlue[600],
  },
  '&:disabled': {
    color: colors.grey[200],
  },
};
export const linkBlack = {
  color: colors.grey[700],
  minWidth: 'max-content',
  gap: '4px',
  padding: 0,
  fontWeight: 600,
  '&:hover': {
    color: colors.marineBlue[500],
  },
  '&:focus': {
    color: colors.marineBlue[500],
  },
  '&:disabled': {
    opacity: 0.25,
    color: colors.grey[700],
  },
};
export const linkWhite = {
  color: colors.white,
  minWidth: 'max-content',
  gap: '4px',
  padding: 0,
  fontWeight: 600,
  '&:hover': {
    color: colors.marineBlue[500],
  },
  '&:focus': {
    color: colors.marineBlue[500],
  },
  '&:disabled': {
    opacity: 0.25,
    color: colors.white,
  },
};

export const lgButton = {
  display: 'inline-flex',
  padding: '10px 16px',
  justifyContent: 'center',
  alignItems: 'center',
  flexShrink: 0,
  fontSize: '16px',
  lineHeight: '24px',
};

export const mdButton = {
  display: 'inline-flex',
  padding: '10px 14px',
  justifyContent: 'center',
  alignItems: 'center',
  flexShrink: 0,
  fontSize: '14px',
  lineHeight: '20px',
};

export const smButton = {
  display: 'inline-flex',
  padding: '8px 12px',
  justifyContent: 'center',
  alignItems: 'center',
  flexShrink: 0,
  fontSize: '14px',
  lineHeight: '20px',
};
export const xlLinkButton = {
  padding: 0,
  alignItems: 'center',
  fontSize: '24px',
  lineHeight: '32px',
};
export const lgLinkButton = {
  padding: 0,
  alignItems: 'center',
  fontSize: '16px',
  lineHeight: '24px',
};

export const mdLinkButton = {
  padding: 0,
  alignItems: 'center',
  fontSize: '14px',
  lineHeight: '20px',
};

export const smLinkButton = {
  padding: 0,
  alignItems: 'center',
  fontSize: '14px',
  lineHeight: '20px',
};
export const BaseButton = styled.button<{ left?: boolean; right?: boolean }>`
  all: unset;
  cursor: pointer;
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 8px;
  color: ${p => p.theme.palette.text.primary};
  margin-left: ${p => (p.left ? '16px' : 0)};
  margin-right: ${p => (p.right ? '16px' : 0)};
`;
