import {
  Select as MuiSelect,
  MenuItem as MuiMenuItem,
  FormControl,
  OutlinedInput,
  Box,
  styled,
  formHelperTextClasses,
  inputBaseClasses,
  inputLabelClasses,
  outlinedInputClasses,
} from '@mui/material';
import { useState } from 'react';
import { Avatar, Chip, InputLabel, textVariant } from 'components';
import { CaretDown, Check, X } from '@phosphor-icons/react';
import type { SelectProps as MuiSelectProps, SelectChangeEvent } from '@mui/material';
import { colors } from 'styles/theme/colors';
import { CommonLabelSeparateProps } from './types';

export type SelectProps = Pick<
  MuiSelectProps,
  'label' | 'multiple' | 'sx' | 'onOpen' | 'onClose' | 'open' | 'defaultOpen' | 'className'
> &
  CommonLabelSeparateProps & {
    id?: string;
    labelID?: string;
    onChange?: (e: SelectChangeEvent<string[]>, o: Option[]) => void;
    variant?: 'standard' | 'filled' | 'outlined' | 'unstyled';
    options: Option[];
    initialValue?: string;
    actions?: JSX.Element;
    iconOnHover?: boolean;
    hasAvatar?: boolean;
    slim?: boolean;
  };

export type Option = {
  label: string;
  avatar?: string;
  id: string;
  value?: string;
  loginId?: string;
  disableAvatar?: boolean;
  onClick?: (event: React.SyntheticEvent) => void;
};

// You have to provide MuiSelect with a component
const SelectIcon = () => {
  return (
    <IconContainer>
      <Icon />
    </IconContainer>
  );
};

const SelectDefault = ({
  id,
  labelID,
  label,
  multiple,
  options,
  variant,
  onChange,
  iconOnHover = false,
  hasAvatar = false,
  actions,
  initialValue,
  slim = false,
  sx,
  className,
  ...rest
}: SelectProps) => {
  const [value, setValue] = useState<string[]>(initialValue ? [initialValue] : []);
  const labels = options.reduce((acc: Record<string, string>, item) => {
    acc[item.id] = item.label;
    return acc;
  }, {});

  const handleChange = (event: SelectChangeEvent<typeof value>) => {
    const { target } = event;
    const selected = typeof target.value === 'string' ? target.value.split(',') : target.value;
    setValue(selected);
    onChange &&
      onChange(
        event,
        options.filter(option => selected.includes(option.id))
      );
  };

  return (
    <FormControl className={className} sx={{ m: 1, width: '100%' }}>
      {label && <InputLabel id={labelID}>{label}</InputLabel>}
      <MuiSelect
        {...rest}
        data-testid="select-component"
        labelId={labelID}
        sx={{
          minHeight: slim ? '32px' : '48px',
          ...sx,
        }}
        id={id}
        multiple={multiple}
        IconComponent={SelectIcon}
        variant={variant === 'unstyled' ? 'standard' : variant}
        value={value}
        onChange={handleChange}
        input={label ? <OutlinedInput label={label} /> : undefined}
        renderValue={
          multiple
            ? renderValues => (
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: '0.25rem' }}>
                  {renderValues.map(renderValue => (
                    <Chip key={renderValue} label={labels[renderValue]} />
                  ))}
                </Box>
              )
            : selected => {
                const currentOption = options.find(option => {
                  return selected.indexOf(option.id) !== -1;
                });

                return (
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: '0.25rem' }}>
                    {hasAvatar && !currentOption?.disableAvatar && (
                      <Avatar
                        size="xs"
                        sx={{ fontSize: '8px' }}
                        src={currentOption?.avatar}
                        letters={currentOption?.label}
                      />
                    )}
                    {selected.map(s => labels[s]).join(', ')}
                  </Box>
                );
              }
        }
      >
        {options.map(option => (
          <MenuItem
            data-testid="menu-item"
            key={option.id}
            value={option.id}
            onClick={option.onClick}
            disableRipple
          >
            <MenuItemContent>
              <div
                style={{
                  display: 'flex',
                  gap: '0.75rem',
                  alignItems: 'center',
                  paddingRight: '10px',
                }}
              >
                {hasAvatar && !option?.disableAvatar && (
                  <Avatar
                    size="xs"
                    sx={{ fontSize: '8px' }}
                    src={option.avatar}
                    letters={option.label}
                  />
                )}
                <span>{option.label}</span>
              </div>
              {value.indexOf(option.id) !== -1 && <Check color={colors.brand[400]} />}
            </MenuItemContent>
          </MenuItem>
        ))}
        {actions && <Actions>{actions}</Actions>}
      </MuiSelect>
    </FormControl>
  );
};

const Actions = styled('li')({
  padding: '0. 1rem',
  fontSize: '1rem',
});

const MenuItem = styled(MuiMenuItem)({
  '&.Mui-selected': {
    backgroundColor: 'inherit',
  },
  '&.Mui-selected:hover': {
    backgroundColor: colors.mintGreen[200],
  },
});

const MenuItemContent = styled('div')({
  display: 'flex',
  width: '100%',
  justifyContent: 'flex-start',
  alignItems: 'center',
});

const IconContainer = styled(Box)`
  user-select: none;
  width: 1em;
  height: 1em;
  display: inline-block;
  fill: currentColor;
  flex-shrink: 0;
  transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  font-size: 1rem;
  position: absolute;
  right: 1em;
  top: calc(50% - 0.5em);
  pointer-events: none;
  color: rgba(0, 0, 0, 0.54);
`;

const Icon = styled(CaretDown)`
  pointer-events: none;
`;

export const Select = styled(
  ({
    labelSeparate,
    success,
    errorIconProps,
    successIconProps,
    successIcon = <Check {...successIconProps} />,
    errorIcon = <X {...errorIconProps} />,
    helperText: hint,
    labelVariant,
    labelText,
    isUnderline,
    ...styledProps
  }: SelectProps) => {
    const helperText =
      hint && styledProps?.error ? (
        <Box lineHeight={0} p={0} m={0} display="flex" alignItems="center" gap="4px">
          {errorIcon} {hint}
        </Box>
      ) : hint && success ? (
        <Box lineHeight={0} p={0} m={0} display="flex" alignItems="center" gap="4px">
          {successIcon} {hint}
        </Box>
      ) : (
        hint
      );
    return <SelectDefault {...styledProps} helperText={helperText} />;
  }
)(({
  theme,
  labelSeparate = true,
  success,
  labelVariant = 'labelSm',
  isUnderline,
  labelText = 'regular',
  ...rest // eslint-disable-next-line
}: any) => {
  const isOutlined = rest?.variant === 'outlined';
  return {
    [`& .${inputLabelClasses.error}`]: {
      color: theme.palette.text.error,
    },

    [`& .${inputLabelClasses.root}`]: {
      color: theme.palette.text.secondary,
      ...(theme.typography?.[labelVariant] ?? {}),
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      ...(textVariant?.[labelText] ?? {}),
      ...(isUnderline ? { textDecoration: 'underline' } : {}),
    },

    [`& .${inputLabelClasses.asterisk}`]: {
      color: theme.palette.text.error,
    },

    [`& .${formHelperTextClasses.root}`]: {
      margin: '0.5px',
      ...theme.typography.labelRegularSm,

      [`&.${formHelperTextClasses.error}`]: {
        color: theme.palette.text.error,
      },
    },

    ...(isOutlined && labelSeparate
      ? {
          input: { borderRadius: '8px' },

          legend: {
            maxWidth: '0px',
          },

          [`& .${inputLabelClasses.outlined}`]: {
            position: 'unset',
            transform: 'none',
            transition: 'none',
          },

          [`& .${outlinedInputClasses.root}`]: {
            borderRadius: '8px',
            marginBottom: '6px',
            marginTop: '6px',
            backgroundColor: theme.palette.bg.primary,

            [`& .${outlinedInputClasses.notchedOutline}`]: {
              borderRadius: '8px',
              borderColor: theme.palette.border.primary,
              color: theme.palette.text.quaternary,
              borderWidth: '0.5px',
            },

            '&:hover': {
              [`& .${outlinedInputClasses.notchedOutline}`]: {
                borderColor: theme.palette.border.brand,
              },
            },

            [`&.${outlinedInputClasses.focused}`]: {
              [`& .${outlinedInputClasses.notchedOutline}`]: {
                borderColor: theme.palette.border.brand,
                borderWidth: '1px',
                boxShadow:
                  '0px 1px 2px 0px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px rgba(22, 105, 99, 0.24)',
              },
            },
          },

          [`& .${inputBaseClasses.root}`]: {
            ...(success ? { backgroundColor: theme.palette.bg.successPrimary } : {}),
            [`&.${inputBaseClasses.error}`]: {
              backgroundColor: theme.palette.bg.errorPrimary,
            },
          },
        }
      : {}),

    // for success
    ...(success
      ? {
          [`& .${inputLabelClasses.root}`]: {
            color: theme.palette.text.success,
            ...(theme.typography?.[labelVariant] ?? {}),
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            ...(textVariant?.[labelText] ?? {}),
            ...(isUnderline ? { textDecoration: 'underline' } : {}),
          },

          [`& .${formHelperTextClasses.root}`]: {
            margin: '0.5px',
            ...theme.typography.labelRegularSm,
            color: theme.palette.text.success,
          },
        }
      : {}),
  };
});
