import type { Components, Theme } from '@mui/material';
import { textVariant } from 'components';
import {
  primary,
  secondary,
  tertiary,
  tertiaryOutline,
  quaternary,
  linkButton,
  lgButton,
  mdButton,
  smButton,
  linkBlack,
  linkWhite,
  smLinkButton,
  mdLinkButton,
  xlLinkButton,
  lgLinkButton,
  lgIconButton,
  mdIconButton,
  smIconButton,
} from 'components/button';

declare module '@mui/material/IconButton' {
  interface IconButtonPropsSizeOverrides {
    large: false;
    medium: false;
    small: false;
    lg: true;
    md: true;
    sm: true;
  }
}

declare module '@mui/material/Button' {
  interface ButtonPropsVariantOverrides {
    primary: true;
    secondary: true;
    tertiary: true;
    quaternary: true;
    link: true;
    linkBlack: true;
    linkWhite: true;
    tertiaryOutline: true;
  }
  interface ButtonPropsSizeOverrides {
    large: false;
    medium: false;
    small: false;
    lg: true;
    md: true;
    sm: true;
    xl: true;
  }
}

export const components: Components<Omit<Theme, 'components'>> | undefined = {
  MuiTypography: {
    defaultProps: { textVariant: 'regular' },
    variants: [
      {
        props: { textVariant: 'regular' },
        style: textVariant.regular,
      },
      {
        props: { textVariant: 'medium' },
        style: textVariant.medium,
      },
      {
        props: { textVariant: 'semiBold' },
        style: textVariant.semiBold,
      },
      {
        props: { textVariant: 'bold' },
        style: textVariant.bold,
      },
    ],
  },
  MuiIconButton: {
    variants: [
      {
        props: { size: 'sm' },
        style: smIconButton,
      },
      {
        props: { size: 'md' },
        style: mdIconButton,
      },
      {
        props: { size: 'lg' },
        style: lgIconButton,
      },
    ],
  },
  MuiButton: {
    defaultProps: {
      disableRipple: true,
    },
    styleOverrides: {
      root: {
        textTransform: 'none',
        fontSize: '14px',
        lineHeight: '20px',
        minWidth: '100px',
        gap: '8px',
        fontWeight: 400,
      },
    },
    variants: [
      {
        props: { variant: 'primary' },
        style: primary,
      },
      {
        props: { variant: 'secondary' },
        style: secondary,
      },
      {
        props: { variant: 'tertiary' },
        style: tertiary,
      },
      {
        props: { variant: 'tertiaryOutline' },
        style: tertiaryOutline,
      },
      {
        props: { variant: 'quaternary' },
        style: quaternary,
      },
      {
        props: { variant: 'link' },
        style: linkButton,
      },
      {
        props: { variant: 'linkBlack' },
        style: linkBlack,
      },
      {
        props: { variant: 'linkWhite' },
        style: linkWhite,
      },
      {
        props: { variant: 'link', size: 'sm' },
        style: smLinkButton,
      },
      {
        props: { variant: 'linkBlack', size: 'sm' },
        style: smLinkButton,
      },
      {
        props: { variant: 'linkWhite', size: 'sm' },
        style: smLinkButton,
      },
      {
        props: { variant: 'link', size: 'md' },
        style: mdLinkButton,
      },
      {
        props: { variant: 'linkBlack', size: 'md' },
        style: mdLinkButton,
      },
      {
        props: { variant: 'linkWhite', size: 'md' },
        style: mdLinkButton,
      },
      {
        props: { variant: 'link', size: 'lg' },
        style: lgLinkButton,
      },
      {
        props: { variant: 'linkBlack', size: 'lg' },
        style: lgLinkButton,
      },
      {
        props: { variant: 'linkWhite', size: 'lg' },
        style: lgLinkButton,
      },
      {
        props: { variant: 'link', size: 'xl' },
        style: xlLinkButton,
      },
      {
        props: { variant: 'linkBlack', size: 'xl' },
        style: xlLinkButton,
      },
      {
        props: { variant: 'linkWhite', size: 'xl' },
        style: xlLinkButton,
      },
      {
        props: { size: 'sm' },
        style: smButton,
      },
      {
        props: { size: 'md' },
        style: mdButton,
      },
      {
        props: { size: 'lg' },
        style: lgButton,
      },
    ],
  },
};
