import {
  Box,
  FormControl,
  FormHelperText,
  MenuItem,
  Select,
  formHelperTextClasses,
  inputBaseClasses,
  inputLabelClasses,
  outlinedInputClasses,
  styled,
} from '@mui/material';
import type {
  InputLabelProps,
  FormControlProps,
  SelectProps,
  FormHelperTextProps,
  SelectChangeEvent,
} from '@mui/material';
import {
  getCountries as getCountriesLib,
  getCountryCallingCode,
  type CountryCode,
} from 'libphonenumber-js';
import { CommonLabelSeparateProps, Locales } from './types';
import { colors } from 'styles/theme/colors';
import { Check, X } from '@phosphor-icons/react';
import { getDisplayNames } from './utils/getDisplayNames';
import { Flag } from './Flag';
import { Text, textVariant } from 'components/text';
import { InputLabel } from './InputLabel';

export interface Country {
  country: CountryCode;
  callingCode?: string;
  title?: string;
}

export const getCountries = (locale: Locales = 'en'): Country[] => {
  const countries = getCountriesLib()?.map(country => {
    return {
      ...(country === 'AC' || country === 'TA' ? { country: `SH-${country}` } : { country }),
      callingCode: getCountryCallingCode(country),
      title: getDisplayNames(locale)?.of(country),
    };
  });
  return (countries ?? []) as Country[];
};

export interface CountrySelectProps
  extends Omit<SelectProps, 'children' | 'onChange'>,
    CommonLabelSeparateProps {
  locale?: Locales;
  label?: React.ReactNode;
  helperText?: React.ReactNode;
  labelId?: string;
  id: string;
  formControlProps?: Omit<FormControlProps, 'className' | 'children'>;
  inputLabelProps?: Omit<InputLabelProps, 'id' | 'required' | 'children'>;
  formHelperTextProps?: Omit<FormHelperTextProps, 'children'>;
  showCountryCode?: boolean;
  value?: Country;
  defaultValue?: Country;
  onChange?: (
    value: Country | undefined,
    event: SelectChangeEvent<unknown>,
    child: React.ReactNode
  ) => void;
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 'auto',
      marginTop: '5px',
    },
    sx: {
      maxHeight: 200,
      overflowY: 'auto',
      '::-webkit-scrollbar': {
        width: '8px',
      },
      '::-webkit-scrollbar-thumb': {
        backgroundColor: colors.mintGreen[600],
        borderRadius: '10px',
      },
      '::-webkit-scrollbar-thumb:hover': {
        backgroundColor: colors.mintGreen[900],
      },
      '::-webkit-scrollbar-track': {
        backgroundColor: colors.mintGreen[300],
        borderRadius: '10px',
      },
    },
  },
};

const CountrySelectDefault = ({
  className,
  label,
  helperText,
  locale,
  id,
  labelId = 'country-select-id',
  required,
  formControlProps,
  inputLabelProps,
  formHelperTextProps,
  showCountryCode,
  onChange: onChangeProps,
  value: valueProps,
  defaultValue: defaultValueProps,
  ...props
}: CountrySelectProps) => {
  const countries = getCountries(locale);
  const value = countries?.find(
    item => item?.country === valueProps?.country || item?.country === defaultValueProps?.country
  )?.country;
  const onChange: SelectProps['onChange'] = (e, c) => {
    const newValue = countries?.find(item => item?.country === e?.target?.value);
    onChangeProps?.(newValue, e, c);
  };
  return (
    <FormControl fullWidth {...formControlProps} className={className}>
      {label && (
        <InputLabel
          {...inputLabelProps}
          isUnderline={props?.isUnderline}
          labelVariant={props?.labelVariant}
          success={props?.success}
          labelText={props?.labelText}
          required={required}
          error={props?.error}
          disabled={props.disabled}
          id={labelId}
        >
          {label}
        </InputLabel>
      )}
      <Select
        {...props}
        value={value}
        MenuProps={MenuProps}
        required={required}
        labelId={labelId}
        onChange={onChange}
        id={id}
        SelectDisplayProps={{ style: { display: 'flex', gap: '8px', alignItems: 'center' } }}
      >
        {countries.map(({ country, title, callingCode }) => {
          return (
            <MenuItem
              key={country}
              value={country}
              sx={{ display: 'flex', gap: '8px', alignItems: 'center' }}
            >
              <Flag nationality={country} /> {showCountryCode && <Text>+{callingCode}</Text>}{' '}
              <Text>{title}</Text>
            </MenuItem>
          );
        })}
      </Select>
      {helperText && <FormHelperText {...formHelperTextProps}>{helperText}</FormHelperText>}
    </FormControl>
  );
};

export const CountrySelect = styled(
  ({
    labelSeparate,
    success,
    errorIconProps,
    successIconProps,
    successIcon = <Check {...successIconProps} />,
    errorIcon = <X {...errorIconProps} />,
    helperText: hint,
    labelVariant,
    labelText,
    isUnderline,
    ...styledProps
  }: CountrySelectProps) => {
    const helperText =
      hint && styledProps?.error ? (
        <Box lineHeight={0} p={0} m={0} display="flex" alignItems="center" gap="4px">
          {errorIcon} {hint}
        </Box>
      ) : hint && success ? (
        <Box lineHeight={0} p={0} m={0} display="flex" alignItems="center" gap="4px">
          {successIcon} {hint}
        </Box>
      ) : (
        hint
      );
    return <CountrySelectDefault {...styledProps} helperText={helperText} />;
  }
)(({
  theme,
  labelSeparate,
  success,
  labelVariant = 'labelSm',
  isUnderline,
  labelText = 'regular',
  ...rest // eslint-disable-next-line
}: any) => {
  const isOutlined = rest?.variant === 'outlined';

  return {
    [`& .${inputLabelClasses.error}`]: {
      color: theme.palette.text.error,
    },

    [`& .${inputLabelClasses.root}`]: {
      color: theme.palette.text.secondary,
      ...(theme.typography?.[labelVariant] ?? {}),
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      ...(textVariant?.[labelText] ?? {}),
      ...(isUnderline ? { textDecoration: 'underline' } : {}),
    },

    [`& .${inputLabelClasses.asterisk}`]: {
      color: theme.palette.text.error,
    },

    [`& .${formHelperTextClasses.root}`]: {
      margin: '0.5px',
      ...theme.typography.labelRegularSm,

      [`&.${formHelperTextClasses.error}`]: {
        color: theme.palette.text.error,
      },
    },

    ...(isOutlined && labelSeparate
      ? {
          input: { borderRadius: '8px' },

          legend: {
            maxWidth: '0px',
          },

          [`& .${inputLabelClasses.outlined}`]: {
            position: 'unset',
            transform: 'none',
            transition: 'none',
          },

          [`& .${outlinedInputClasses.root}`]: {
            borderRadius: '8px',
            marginBottom: '6px',
            marginTop: '6px',
            backgroundColor: theme.palette.bg.primary,

            [`& .${outlinedInputClasses.notchedOutline}`]: {
              borderRadius: '8px',
              borderColor: theme.palette.border.primary,
              color: theme.palette.text.quaternary,
              borderWidth: '0.5px',
            },

            '&:hover': {
              [`& .${outlinedInputClasses.notchedOutline}`]: {
                borderColor: theme.palette.border.brand,
              },
            },

            [`&.${outlinedInputClasses.focused}`]: {
              [`& .${outlinedInputClasses.notchedOutline}`]: {
                borderColor: theme.palette.border.brand,
                borderWidth: '1px',
                boxShadow:
                  '0px 1px 2px 0px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px rgba(22, 105, 99, 0.24)',
              },
            },
          },

          [`& .${inputBaseClasses.root}`]: {
            ...(success ? { backgroundColor: theme.palette.bg.successPrimary } : {}),
            [`&.${inputBaseClasses.error}`]: {
              backgroundColor: theme.palette.bg.errorPrimary,
            },
          },
        }
      : {}),

    // for success
    ...(success
      ? {
          [`& .${inputLabelClasses.root}`]: {
            color: theme.palette.text.success,
            ...(theme.typography?.[labelVariant] ?? {}),
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            ...(textVariant?.[labelText] ?? {}),
            ...(isUnderline ? { textDecoration: 'underline' } : {}),
          },

          [`& .${formHelperTextClasses.root}`]: {
            margin: '0.5px',
            ...theme.typography.labelRegularSm,
            color: theme.palette.text.success,
          },
        }
      : {}),
  };
});
